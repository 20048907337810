/* @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer base {
  .text-gradient {
    background-color: #fff;
    background: linear-gradient(145deg, #2042b8 10.3%, #ff6600 117.2%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .bg-gradient {
    background-color: #0f0e13;
    background-image: linear-gradient(
      141.55deg,
      #ff6600 -3.79%,
      #2042b8 82.82%
    );
  }

  .border-gradient {
    border: 4px solid #ff6600;
    outline: 4px solid #2042b8;
    /* background-color: #0f0e13; */
  }
}
